export const externalLinks = {
    shop: 'https://www.solodou-store.com/collections/all',
    signup: 'https://solodou.com/connexion',
    appStore: 'https://apps.apple.com/us/app/solodou/id1611502148',
    playStore: 'https://play.google.com/store/apps/details?id=com.solodou.application',
    baba1: 'https://solodou.com/b-a-ba-1/cours/l-alphabet-francais-1',
    baba2: 'https://solodou.com/b-a-ba-2/cours/les-articles-definis-indefinis'
};

export const CONDITION_SALE_FILE = '/conditions-of-sale.pdf';
export const PRIVACY_POLICY_FILE = '/privacy-policy';
