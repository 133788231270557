import React from 'react';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/youtube.svg';
import { SOCIAL_NETWORK_URLS } from 'config/constants';
import './Footer.scss';
import { CONDITION_SALE_FILE, PRIVACY_POLICY_FILE } from 'utils/constants';

const isProduction = process.env.REACT_APP_ENV === 'production';

const Footer = () => {
    return (
        <section className='Footer' id='Footer'>
            <div className='Footer__sections-container'>
                {
                    data.map(section => (
                        <div key={section.id} className='Footer__sections-container__section'>
                            <span className='Footer__sections-container__section__heading'>{section.title}</span>
                            <div className='Footer__sections-container__section__links-container'>
                                {
                                    section.items.map(item => (
                                        <a key={item.id} className='Footer__sections-container__section__links-container__link' href={item.link}
                                            target='_blank' rel='noreferrer'>
                                            {item.title}
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
                <div className='Footer__sections-container__section'>
                    <span className='Footer__sections-container__section__heading'>SUIVRE SOLODOU</span>
                    <div className='Footer__sections-container__section__links-container'>
                        <a href='tel:+919874512345' className='Footer__sections-container__section__links-container__link'>
                            +033 - (0) 7 67 59 59 14
                        </a>
                        <span className='Footer__sections-container__section__links-container__email'>
                            <span>Email</span> - <a href='mailto:xyz@gmail.com' target='_blank' rel='noreferrer'>contact@solodou.com</a>
                        </span>

                        <div className='Footer__sections-container__section__links-container__social-links'>
                            {
                                socialItems.map(item => (
                                    <a key={item.id} className='Footer__sections-container__section__links-container__social-links__item' href={item.link}
                                        target='_blank' rel='noreferrer'>
                                        {item.icon}
                                    </a>
                                ))
                            }
                        </div>
                        <span className='Footer__sections-container__section__links-container__other_applications'>
                            <a href='https://solodou-staging.s3.eu-west-3.amazonaws.com/solodou-production/pdf/other-applications.pdf' target='_blank' rel='noreferrer'>Autres applications</a>
                        </span>
                    </div>
                </div>
            </div>
            <div className='Footer__copyright'>
                <span>Copyright © {new Date().getUTCFullYear()} Solodou</span>
            </div>
        </section>
    );
};

const data = [
    {
        id: 1,
        title: 'INFOS SOLODOU',
        items: [
            {
                id: 11,
                title: 'Qu\'est-ce que SOLODOU ?',
                link: 'https://solodou-staging.s3.eu-west-3.amazonaws.com/solodou-production/pdf/que_st_donc_solodou.pdf'
                // link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/que_st_donc_solodou.pdf`
            },
            {
                id: 12,
                title: 'Auteur - Le Fondateur',
                link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/auteur.pdf`
            },
            {
                id: 13,
                title: 'Motivations & valeurs',
                link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/motivations_et_valeurs.pdf`
            },
            {
                id: 14,
                title: 'Soutiens',
                link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/soutiens.pdf`
            },
            {
                id: 15,
                title: 'Remerciements',
                link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/remerciements.pdf`
            },
            {
                id: 16,
                title: 'Tableau d\'Honneur ',
                link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/tableau_d_honneur.pdf`
            },
            {
                id: 17,
                title: 'Ambassadeurs',
                link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/ambassadeurs.pdf`
            },
            {
                id: 18,
                title: 'Démo - L\'alpha avec Solodou',
                link: 'https://youtu.be/AaJEv3h4EmA'
            }
        ]
    },
    {
        id: 2,
        title: 'QUESTIONS / AVIS ?',
        items: [
            {
                id: 21,
                title: 'Bon à savoir !',
                link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/bon_savoir.pdf`
            },
            {
                id: 22,
                title: 'Prix du KIT & Adresses',
                link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/prix_adresses_et_telephones.pdf`
            },
            {
                id: 23,
                title: 'Contacter le fondateur',
                link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/contacter_le_fondateur.pdf`
            },
            {
                id: 24,
                title: 'Foires aux Questions',
                link: '/frequent-questions.pdf'
                // link: 'https://solodou-staging.s3.eu-west-3.amazonaws.com/solodou-production/pdf/foires_aux_questions.pdf'
                // link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/foires_aux_questions.pdf`
            },
            {
                id: 25,
                title: 'Mentions Légales',
                link: 'https://solodou-staging.s3.eu-west-3.amazonaws.com/solodou-production/pdf/mentions_legales.pdf'
                // link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/mentions_legales.pdf`
            },
            {
                id: 26,
                title: 'Conditions Générales d\'Utilisation',
                link: 'https://solodou-staging.s3.eu-west-3.amazonaws.com/solodou-production/pdf/terms_of_service.pdf'
                // link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/conditions_generales_d_utilisation.pdf`
            },
            {
                id: 27,
                title: 'Condition Générales De Vente',
                link: CONDITION_SALE_FILE
                // link: `https://s3-eu-west-3.amazonaws.com/solodou-${isProduction ? 'production' : 'staging'}/pdf/conditions_generales_d_utilisation.pdf`
            },
            {
                id: 28,
                title: 'Politique De Confidentialité',
                link: PRIVACY_POLICY_FILE
            }
        ]
    }
];

const socialItems = [
    {
        id: 1,
        name: 'Twitter',
        icon: <TwitterIcon />,
        link: SOCIAL_NETWORK_URLS.TWITTER
    },
    {
        id: 2,
        name: 'Facebook',
        icon: <FacebookIcon />,
        link: SOCIAL_NETWORK_URLS.FACEBOOK
    },
    {
        id: 3,
        name: 'LinkedIn',
        icon: <LinkedInIcon />,
        link: SOCIAL_NETWORK_URLS.LINKEDIN
    },
    {
        id: 4,
        name: 'Youtube',
        icon: <YoutubeIcon />,
        link: SOCIAL_NETWORK_URLS.YOUTUBE
    }
];

export default Footer;
