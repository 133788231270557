import { lazy } from 'react';

export const LoginContainer = lazy(() => import('containers/Login/LoginContainer'));
export const HomeContainer = lazy(() => import('containers/Home/HomeContainer'));
export const BlogContainer = lazy(() => import('containers/Blog/BlogContainer'));

export const ComingSoonContainer = lazy(() => import('containers/ComingSoon/ComingSoonContainer'));
export const PageNotFoundContainer = lazy(() => import('containers/PageNotFound/PageNotFoundContainer'));
export const FacebbokDeleteAccountContianer = lazy(() => import('containers/FacebookDeleteAccount/FacebbokDeleteAccountContianer'));
export const PrivacyPolicyContainer = lazy(() => import('containers/PrivacyPolicy/PrivacyPolicyContainer'));

export const TestingContainer = lazy(() => import('containers/_Testing/TestingContainer'));
