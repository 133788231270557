/* eslint-disable react/jsx-max-props-per-line */

import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import AppLoading from '../Loading/AppLoading';
import { Switch, Route } from 'react-router-dom';
import { ROUTES } from 'config/constants';
import { HomeContainer, BlogContainer, TestingContainer, PageNotFoundContainer, ComingSoonContainer, FacebbokDeleteAccountContianer, PrivacyPolicyContainer } from './config/routes';

export default class AppRouter extends Component {
    static propTypes = {
        isAuthed: PropTypes.bool.isRequired
    }

    shouldComponentUpdate(nextProps) {
        const { isAuthed } = this.props;

        if (isAuthed !== nextProps.isAuthed) {
            return true;
        }

        return false;
    }

    render() {
        const { isAuthed } = this.props;

        return (
            <Suspense fallback={<AppLoading />}>
                <Switch>
                    <Route path={ROUTES.HOME} exact={true} component={HomeContainer} isAuthed={isAuthed}/>
                    <Route path={ROUTES.BLOG} exact={true} component={BlogContainer} isAuthed={isAuthed}/>
                    <Route path={ROUTES.COMING_SOON} exact={true} component={ComingSoonContainer} isAuthed={isAuthed}/>
                    <Route path={ROUTES.FB_DELETE_ACCOUNT} exact={true} component={FacebbokDeleteAccountContianer} isAuthed={isAuthed}/>
                    <Route path={ROUTES.PRIVAY_POLICY} exact={true} component={PrivacyPolicyContainer} />

                    <Route path={ROUTES._TESTING} exact={true} component={TestingContainer} />

                    <Route component={PageNotFoundContainer} />
                </Switch>
            </Suspense>
        );
    }
}
