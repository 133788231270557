import React, { useEffect, useState } from 'react';
import { ReactComponent as ShopIcon } from 'assets/icons/shop.svg';
import { externalLinks } from 'utils/constants';

import './MobileNav.scss';

const MobileNav = () => {
    const [isOpen, setIsOpen] = useState(false);

    const onNavToggle = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // disabling scroll when popup opens
        if (isOpen) {
            document.querySelector('html').style.overflow = 'hidden';
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('html').style.overflow = 'auto';
            document.querySelector('body').style.overflow = 'auto';
        }
    }, [isOpen]);

    const onItemClick = (name) => {
        setIsOpen(!isOpen);
        const el = document.getElementById(name);
        const y = el.getBoundingClientRect().top + window.pageYOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    return (
        <div className={`MobileNav ${isOpen ? 'MobileNav--show' : ''}`}>
            <div className='MobileNav__button'>
                <div className='MobileNav__button__background'>
                    <div className='MobileNav__button__background__ratio'/>
                </div>
                <div className='MobileNav__button__bars-container' onClick={onNavToggle}>
                    <div className='MobileNav__button__bars-container__bar' />
                    <div className='MobileNav__button__bars-container__bar' />
                    <div className='MobileNav__button__bars-container__bar' />
                    <div className='MobileNav__button__bars-container__bar' />
                </div>
            </div>
            <div className='MobileNav__nav-contents' />
            <ul className='MobileNav__nav-contents__items-container'>
                {navItems.map((item) => (
                    <li className='MobileNav__nav-contents__items-container__item' key={item.id} onClick={() => onItemClick(item.tagId)}>
                        {item.name}
                    </li>
                ))}
            </ul>

            <div className='MobileNav__button-row'>
                <a className='MobileNav__button-row__item' href={externalLinks.signup}>Se connecter</a>
                <a className='MobileNav__button-row__item' href={externalLinks.shop}>
                    <span style={{ marginRight: '10px' }}>
                        <ShopIcon />
                    </span>
                    Boutique
                </a>
            </div>
        </div>
    );
};

const navItems = [
    {
        id: 1,
        name: 'Accueil',
        tagId: 'HomeScreenHeader'
    },
    {
        id: 3,
        name: 'Services',
        tagId: 'HomeScreenSolodouMethod'
    },
    {
        id: 2,
        name: 'Avis utilisateurs',
        tagId: 'HomeScreenReviews'
    },
    {
        id: 4,
        name: 'Tarifs',
        tagId: 'HomeScreenPricingPlans'
    },
    {
        id: 5,
        name: 'Nous contacter',
        tagId: 'Footer'
    }
];

export default MobileNav;
