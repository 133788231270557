import React from 'react';
import { useLocation } from 'react-router-dom';
import MobileNav from 'components/MobileNav/MobileNav';
import { ReactComponent as ShopIcon } from 'assets/icons/shop.svg';
import Logo from 'assets/icons/logo.png';
import { externalLinks } from 'utils/constants';

import './HeaderNav.scss';
import { ROUTES } from 'config/constants';

const HeaderNav = () => {
    const location = useLocation();

    const onItemClick = (name) => {
        const element = document.getElementById(name);

        if (element) {
            element && element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        } else {
            window.location = ROUTES.HOME;
        }
    };

    return (
        <div className={`HeaderNav ${location.pathname !== ROUTES.HOME ? 'HeaderNav--inverted' : ''}`}>
            {/* <Link className='HeaderNav__logo-container' to={ROUTES.HOME}>
                <img className='HeaderNav__logo-container__logo' src={Logo} alt='Solodou Logo' />
            </Link> */}
            <a className='HeaderNav__logo-container' href={ROUTES.HOME}>
                <img className='HeaderNav__logo-container__logo' src={Logo} alt='Solodou Logo' />
            </a>
            <div className='HeaderNav__nav'>
                <ul className='HeaderNav__items-container'>
                    {
                        navItems.map(item => (
                            <li key={item.id} className='HeaderNav__items-container__item HeaderNav__button'
                                onClick={() => onItemClick(item.tagId)}>
                                {item.name}
                            </li>
                        ))
                    }
                </ul>
                <div className='HeaderNav__buttons-container'>
                    <a className='HeaderNav__buttons-container__button HeaderNav__button' href={ROUTES.COMING_SOON}
                        rel='noreferrer'>
                        Se connecter
                    </a>
                    <a className='HeaderNav__buttons-container__button HeaderNav__button' href={externalLinks.shop} target='_blank'
                        rel='noreferrer'>
                        <div className='HeaderNav__buttons-container__button__icon'>
                            <ShopIcon />
                        </div>
                        Boutique
                    </a>
                </div>
            </div>
            <MobileNav />
        </div>
    );
};

const navItems = [
    {
        id: 1,
        name: 'Accueil',
        tagId: 'HomeScreenHeader'
    },
    {
        id: 3,
        name: 'Services',
        tagId: 'HomeScreenSolodouMethod'
    },
    {
        id: 2,
        name: 'Avis utilisateurs',
        tagId: 'HomeScreenReviews'
    },
    {
        id: 4,
        name: 'Tarifs',
        tagId: 'HomeScreenPricingPlans'
    },
    {
        id: 5,
        name: 'Nous contacter',
        tagId: 'Footer'
    }
];

export default HeaderNav;
