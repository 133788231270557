/***
 *
 * NOTE: This is ONLY for <Helmet /> in `src/containers/App/AppContainer`
 *
 */
import { getAppLanguage } from 'redux/reducers/utils/i18n/helpers';
import { FACEBOOK_APP_ID } from 'config/constants';

const STATIC_CONFIG = {
    image: 'TODO_REPLACE(optimal: 1200px x 630px (max 5mb, ratio: 1.91:1))',
    siteName: 'Solodou',
    type: 'website',
    language: getAppLanguage(),
    twitterSite: '@solodou_france',
    twitterCard: 'SOLODOU est la première plateforme numérique d\'alphabétisation pour les adultes. L\'apprentissage s\'y fait en toute AUTONOMIE !',
    fbAppId: FACEBOOK_APP_ID,
    children: null
};

// HELMET - see https://github.com/SchwSimon/creatella-react-components#helmet-
const helmet = {
    en: {
        ...STATIC_CONFIG,
        description: 'SOLODOU is the first digital literacy platform for adults. Learning is done in complete AUTONOMY!\n',
        title: 'Solodou'
    },
    fr: {
        ...STATIC_CONFIG,
        description: 'SOLODOU est la première plateforme numérique d\'alphabétisation pour les adultes. L\'apprentissage s\'y fait en toute AUTONOMIE !',
        title: 'Solodou'
    }
    // de: { ... }
    // fr: { ... }
    // ...
};

export default helmet;
