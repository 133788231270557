/****
 *
 * window & process.env constants
 *
 */
export const ELOQUENT = window.Eloquent;
export const AXIOS = window._axios;

// see https://github.com/SchwSimon/creatella-react-components#withasynccaller
window._withAsyncCallerAxios = window._BaseAxios;

export const APP_ENV = process.env.REACT_APP_ENV;
export const APP_URL = process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

/****
 *
 * Storage keys
 *
 */
export const STORAGEKEY_AUTH_TOKEN = 'sk-authToken';
export const STORAGEKEY_APP_LANGUAGE = 'sk-appLanguage';

/****
 *
 * Configs
 *
 */
// App default language (also used for the <html /> lang='' attribute)
// see https://developer.paciellogroup.com/blog/2016/06/using-the-html-lang-attribute/
// NOTE: make sure the language key defined here matches the default helmet key
// // see `executors/i18n/helmet`
export const APP_LANGUAGE_DEFAULT = 'fr';

// App environments
// see .env.production & .env.development
// values must match the values defined in these files
export const APP_ENVS = {
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

// SENTRY - see https://docs.sentry.io/error-reporting/configuration/?platform=javascript
export const SENTRY_CONFIG = {
    environment: APP_ENV,
    dsn: SENTRY_DSN
};

// WEBFONT config - see https://github.com/typekit/webfontloader
export const WEBFONT_CONFIG = {
    google: {
        families: [
            'Poppins:300',
            'Poppins:400',
            'Poppins:500',
            'Poppins:600',
            'Poppins:700',
            'Poppins:900',
            'Montserrat:400',
            'Montserrat:700',
            'Nunito:700'
        ]
    }
};

// Social networks config
export const SOCIAL_NETWORK_URLS = {
    FACEBOOK: 'https://www.facebook.com/solodou.france',
    TWITTER: 'https://twitter.com/solodou_france',
    LINKEDIN: 'https://www.linkedin.com/in/ousmane-solodou-884708144/',
    YOUTUBE: 'https://www.youtube.com/channel/UCK8kwRRVkCIhH5n1URcHdbA'
    // ...
};

export const FACEBOOK_APP_ID = 'TODO_REPLACE';

/****
 *
 * App routing
 *
 */
export const ROUTES = {
    HOME: '/',
    BLOG: '/blog',
    COMING_SOON: '/coming-soon',
    LOGIN: '/login',
    PRIVAY_POLICY: '/privacy-policy',

    FB_DELETE_ACCOUNT: '/instruction/fb-delete-account',

    _TESTING: '/testing'
};

export const ROUTE_REDIRECT_AFTER_REGISTRATION = 'TODO_REPLACE(ex.: ROUTES.HOME)';
export const ROUTE_REDIRECT_AFTER_LOGIN = 'TODO_REPLACE(ex.: ROUTES.HOME)';
export const ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE = '/';
export const ROUTE_REDIRECT_FROM_AUTHED_ROUTE = '/';

/****
 *
 * Project config
 *
 * merge to main branch to deploy
 * PRODUCTION: https://solodou.fr/
 * STAGING: https://qih26y8nk7xm5mrf.web.solodou.fr
 */

// ...
