import { getAppLanguage, saveAppLanguage } from './utils/i18n/helpers';

const I18N_SET_LANGUAGE = 'I18N_SET_LANGUAGE';

export const setAppLanguage = (language) => ({
    type: I18N_SET_LANGUAGE,
    language
});

const initialState = {
    language: getAppLanguage()
};

export default function i18n(state = initialState, action) {
    switch (action.type) {
        case I18N_SET_LANGUAGE: {
            const { language } = action;

            saveAppLanguage(language);

            // i18next.changeLanguage(language);

            return {
                ...state,
                language
            };
        }

        default:
            return state;
    }
}
